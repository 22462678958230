export const VARIATION_PLUS = "+";
export const VARIATION_MINUS = "-";

export const signVariationOption = {
  [VARIATION_PLUS]: "+",
  [VARIATION_MINUS]: "-",
};

export const VARIATION_PRICE = "price";
export const VARIATION_PERCENT = "percent";

export const calculationVariationOption = {
  [VARIATION_PRICE.toUpperCase()]: VARIATION_PRICE,
  [VARIATION_PERCENT.toUpperCase()]: VARIATION_PERCENT,
};

export const TYPE_STANDARD = "standard";
export const TYPE_DERIVED = "derived";

export const pricingOption = {
  [TYPE_STANDARD]: "standard",
  [TYPE_DERIVED]: "derived",
};
