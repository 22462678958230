export default {
  type: "pricing-rules",
  name: null,
  value: null,
  pricing_type: "price",
  weekend_value: null,
  variation: null,
  seasonal: false,
  start_date: null,
  description: null,
  end_date: null,
  per_person: false,
  pricing_rule_type: "standard",
  minimum_person: 1,
  maximum_person: 9999,
  minimum_stay: 1,
  maximum_stay: 9999,
  created_at: null,
  relationshipNames: ["spotTypes", "organization", "derivedFrom"],
  spotTypes: [],
  organization: {
    type: "organizations",
    id: null,
  },
  derivedFrom: {
    type: "pricing-rules",
    id: null,
  },
};
