<template>
  <div class="step-content-body">
    <div class="step-content-body-title">
      <div class="step-content-body-title-left">
        <p>{{ $t("PRICING_RULES.RESTRICTIONS") }}</p>
      </div>
      <div class="step-content-body-title-right"></div>
    </div>
    <div class="form-group-wrapper">
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.MINIMUM_NIGHT')}`">
          <el-input-number
            v-model="minimumNight"
            controls-position="right"
            :min="1"
            :max="9999"
          ></el-input-number>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.MAXIMUM_NIGHT')}`">
          <el-input-number
            v-model="maximumNight"
            controls-position="right"
            :min="1"
            :max="9999"
          ></el-input-number>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.MINIMUM_PERSON')}`">
          <el-input-number
            v-model="minimumPerson"
            controls-position="right"
            :min="1"
            :max="9999"
          ></el-input-number>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.MAXIMUM_PERSON')}`">
          <el-input-number
            v-model="maximumPerson"
            controls-position="right"
            :min="1"
            :max="9999"
          ></el-input-number>
        </base-input>
      </div>
      <!-- <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.ACTIVE_FROM')}`">
          <el-input-number
            v-model="activeFrom"
            controls-position="right"
            :min="0"
            :max="200"
          ></el-input-number>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.ACTIVE_UNTIL')}`">
          <el-input-number
            v-model="activeUntil"
            controls-position="right"
            :min="0"
            :max="200"
          ></el-input-number>
        </base-input>
      </div> -->
    </div>
  </div>
</template>
<script>
import { InputNumber } from "element-ui";
export default {
  name: "restrictions-step",

  components: {
    [InputNumber.name]: InputNumber,
  },

  props: {
    minStay: {
      type: Number,
      default: 0,
      description: "Disable the input",
    },
    maxStay: {
      type: Number,
      default: 0,
      description: "Disable the input",
    },
    minPerson: {
      type: Number,
      default: 0,
      description: "Disable the input",
    },
    maxPerson: {
      type: Number,
      default: 0,
      description: "Disable the input",
    },
  },

  mixins: [],

  data() {
    const minimumNight = this.minStay;
    const maximumNight = this.maxStay;
    const minimumPerson = this.minPerson;
    const maximumPerson = this.maxPerson;
    return {
      minimumNight,
      maximumNight,
      minimumPerson,
      maximumPerson,
      // activeFrom: 0,
      // activeUntil: 0,
    };
  },

  computed: {},

  methods: {},

  watch: {
    minimumNight(value) {
      this.$emit("update:minStay", value);
    },
    maximumNight(value) {
      this.$emit("update:maxStay", value);
    },
    minimumPerson(value) {
      this.$emit("update:minPerson", value);
    },
    maximumPerson(value) {
      this.$emit("update:maxPerson", value);
    },
  },
};
</script>
