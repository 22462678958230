<template>
  <div class="container-fluid">
    <pricing-rule-form
      :loading="loading"
      :pricingRuleData="pricingRule"
      :formErrors="formErrors"
      @pricingRuleSubmitted="handleSubmit"
      @formChanged="() => (alertLeave = true)"
      @onClosePricingRuleModal="onClosePricingRuleModal"
    />
  </div>
</template>

<script>
import { Select, Option } from "element-ui";
import { cloneDeep } from "lodash";
import alertLeave from "@/mixins/alert-leave-mixin";
import PricingRuleForm from "../partials/PricingRuleForm.vue";
import defaultPricingRule from "../defaultPricingRule";
import { TYPE_STANDARD } from "@/constants/pricingRules";
import { mapGetters } from "vuex";

export default {
  layout: "DashboardLayout",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    PricingRuleForm,
  },

  mixins: [alertLeave],
  computed: {
    ...mapGetters("organizations", {
      globalOrganization: "globalOrganization",
    }),
    ...mapGetters("organizationOptions", {
      organizationConfig: "organizationConfig",
    }),
  },
  data() {
    return {
      pricingRule: cloneDeep({
        ...defaultPricingRule,
        organization: {
          type: "organizations",
          id: this.globalOrganization?.id,
        },
      }),
      formErrors: null,
      loading: false,
    };
  },

  created() {
    this.pricingRule.organization.id = this.globalOrganization?.id;
  },

  methods: {
    async handleSubmit(pricingRule) {
      this.loading = true;

      const pricingRuleData = cloneDeep(pricingRule);
      delete pricingRuleData.id;
      if (pricingRule.pricing_rule_type === TYPE_STANDARD) {
        delete pricingRuleData.derivedFrom;
      }

      try {
        await this.$store.dispatch("pricingRules/add", pricingRuleData);
        this.$notify({
          type: "success",
          message: this.$t("PRICING_RULES.PRICING_RULE_ADDED"),
        });
        const pricingRule = await this.$store.getters[
          "pricingRules/pricingRule"
        ];
        this.$emit("onViewPricingRule", pricingRule, true);
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.formErrors = error.response.data.errors;
        this.loading = false;
      }
    },

    onClosePricingRuleModal() {
      this.$emit("onClosePricingRuleModal");
    },
  },
};
</script>
