<template>
  <div class="step-content-body">
    <div class="step-content-body-title">
      <div class="step-content-body-title-left">
        <p>{{ $t("PRICING_RULES.COSTS") }}</p>
      </div>
      <div class="step-content-body-title-right"></div>
    </div>
    <div class="form-group-wrapper">
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.BUNDLE')} (*)`">
          <el-select
            v-model="bundleValue"
            :placeholder="`${$t('PRICING_RULES.BUNDLE')}`"
          >
            <el-option
              v-for="bundle in bundles"
              :key="bundle.value"
              :label="bundle.label"
              :value="bundle.value"
            >
            </el-option>
          </el-select>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.LODGING_TAX')} (*)`">
          <el-radio-group v-model="LodgingTax">
            <el-radio label="1">{{ $t("COMMON.YES") }}</el-radio>
            <el-radio label="2">{{ $t("COMMON.NO") }}</el-radio>
          </el-radio-group>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.ADULTADDCOST')} (*)`">
          <el-input-number
            v-model="adultAddCost"
            controls-position="right"
            :min="1"
            :max="200"
          ></el-input-number>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.CHILDRENADDCOST')} (*)`">
          <el-input-number
            v-model="childrenAddCost"
            controls-position="right"
            :min="1"
            :max="200"
          ></el-input-number>
        </base-input>
      </div>
    </div>
  </div>
</template>
<script>
import { Option, Radio, RadioGroup, Select, InputNumber } from "element-ui";
export default {
  name: "costs-step",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [InputNumber.name]: InputNumber,
  },

  props: [],

  mixins: [],

  data() {
    return {
      bundles: [
        {
          value: 1,
          label: "Bundle 1",
        },
        {
          value: 2,
          label: "Bundle 2",
        },
      ],
      bundleValue: "",
      LodgingTax: "1",
      adultAddCost: 1,
      childrenAddCost: 1,
    };
  },

  computed: {},

  methods: {},

  watch: {},
};
</script>
