<template>
  <el-select
    v-model="spotTypesModel"
    @change="spotTypesChanged"
    autocomplete="new-password"
    collapse-tags
    :multiple="true"
    :placeholder="placeholder ?? $t('COMMON.TYPE')"
    :filterable="filterable"
    :disabled="disabled"
    remote
    :remote-method="getTypes"
    :loading="loading"
    :allow-create="allowCreate"
    :key="key"
  >
    <el-option v-if="showAll" value="all" :label="$t('COMMON.ALL')">
    </el-option>
    <el-option v-if="allowNone" :value="null" :label="$t('COMMON.NONE')">
    </el-option>
    <el-option
      v-for="spotType in spotTypesList"
      :key="spotType.id"
      :value="spotType.id"
      :label="spotType.name"
    >
    </el-option>
  </el-select>
</template>

<script>
import { Option, Select } from "element-ui";
import { isEqual, cloneDeep } from "lodash";
export default {
  name: "spot-types-selector",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
  },

  props: {
    disabled: {
      type: Boolean,
      default: false,
      description: "Disable the input",
    },
    allowCreate: {
      type: Boolean,
      default: false,
      description: "Allow tag creation",
    },
    showAll: {
      type: Boolean,
      default: true,
      description: "Show the option all",
    },
    allowNone: {
      type: Boolean,
      default: false,
      description: "Show the option none",
    },
    filterable: {
      type: Boolean,
      default: true,
      description: "Can filter",
    },

    spotTypes: {
      type: Array,
      default: null,
      description: "Spot type array",
    },
    filterSpotCategory: {
      type: String,
      default: null,
      description: "SpotCategory id",
    },
    placeholder: {
      type: String,
      default: null,
      description: "Placeholder",
    },
  },

  data() {
    return {
      spotTypesModel: this.spotTypes.map((item) => item.id),
      spotTypesList: {},
      key: 46,
    };
  },

  setup() {},

  created() {
    this.getTypes(null, this.spotType);
  },

  methods: {
    async getTypes(query = null, id = null) {
      try {
        this.loading = true;
        let params = {
          sort: "name",
          filter: {
            ...(query ? { search: query } : {}),
            ...(id ? { id: id } : {}),
          },
          page: {
            number: 1,
            size: 10,
          },
        };

        if (this.filterSpotCategory) {
          params = {
            ...params,
            filter: {
              ...params.filter,
              in_category: this.filterSpotCategory,
            },
          };
        }

        await this.$store.dispatch("spotTypes/list", params);
        //   const spotTypesArr = await this.$store.getters["spotTypes/list"];
        this.spotTypesList = await this.$store.getters["spotTypes/list"];
        //  spotTypesArr.sort((a, b) => a.name.localeCompare(b.name))
        // console.log(spotTypesArr);
        //   this.spotTypesList = {};
        //   spotTypesArr.forEach((spotType) => {
        //     this.spotTypesList[spotType.id] = spotType;
        //   });
        this.loading = false;
      } catch (error) {
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
        this.loading = false;
      }
    },

    spotTypesChanged(spotTypes) {
      this.$emit(
        "spotTypesChanged",
        spotTypes
          .filter((el) => el !== "all")
          .map((item) => {
            return { id: item, type: "spot-types" };
          })
      );
    },

    arraysEqual(arr1, arr2) {
      if (arr1.length !== arr2.length) {
        return false;
      }

      const sortedArr1 = [...arr1].sort();
      const sortedArr2 = [...arr2].sort();

      for (let i = 0; i < sortedArr1.length; i++) {
        if (sortedArr1[i] !== sortedArr2[i]) {
          return false;
        }
      }

      return true;
    },
  },

  watch: {
    spotTypesModel: {
      handler(values, oldValues) {
        const spotTypesValues = cloneDeep(values);
        const allSelected = values.includes("all");
        const allValues = this.spotTypesList.map((spotType) => spotType.id);
        const allValuesSelected = this.arraysEqual(
          spotTypesValues.filter((el) => el !== "all"),
          allValues
        );

        if (!oldValues.includes("all") && allSelected) {
          this.spotTypesModel = [...allValues, "all"];
        } else if (
          oldValues.includes("all") &&
          !allSelected &&
          allValuesSelected
        ) {
          this.spotTypesModel = [];
        } else {
          if (allValuesSelected && !allSelected) {
            this.spotTypesModel = [...values, "all"];
          } else if (allSelected && !allValuesSelected) {
            this.spotTypesModel = values.filter((el) => el !== "all");
          }
        }
        this.spotTypesChanged(spotTypesValues);
      },
      deep: true,
    },

    spotTypes: {
      handler: function (spotTypes) {
        const spotTypesValues = cloneDeep(spotTypes.map((type) => type.id));
        const spotTypesModel = cloneDeep(
          this.spotTypesModel.filter((el) => el !== "all")
        );
        if (this.arraysEqual(spotTypesModel, spotTypesValues)) {
          return;
        }
        this.spotTypesModel = spotTypesValues;
      },
      deep: true,
    },
    filterSpotCategory() {
      this.getTypes();
    },
  },
};
</script>
