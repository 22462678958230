<template>
  <div class="elite-tabs-wrapper-content">
    <h3>{{ pricingRule.name }}</h3>
    <div class="all-infos">
      <div class="all-infos-text">
        <dl class="row" v-if="pricingRule.name">
          <dt>{{ $t("PRICING_RULES.PRICING_RULE_NAME") }}</dt>
          <dd>
            {{ pricingRule.name }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_RULES.PRICING_RULE_PRICING_TYPE") }}</dt>
          <dd>
            {{ pricingRule.pricing_rule_type }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PRICING_RULES.VARIATION_IN_PRICE_OR_PERCENT") }}</dt>
          <dd>
            {{ pricingRule.pricing_type }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_RULES.VARIATION_PLUS_OR_MINUS") }}</dt>
          <dd>
            {{
              pricingRule.variation ??
              $t("PRICING_RULES.EMPTY_VARIATION_PLUS_OR_MINUS")
            }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("PRICING_RULES.PRICING_RULE_VALUE") }}</dt>
          <dd>
            {{ pricingRule.value }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PRICING_RULES.END_OF_WEEK") }}</dt>
          <dd>
            {{ pricingRule.weekend_value }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("PRICING_RULES.IS_SEASONAL") }}</dt>
          <dd>
            {{ pricingRule.seasonal ? $t("COMMON.YES") : $t("COMMON.NO") }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.start_date !== null">
          <dt>{{ $t("PRICING_RULES.START_DATE") }}</dt>
          <dd>
            {{ pricingRule.start_date }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.end_date !== null">
          <dt>{{ $t("PRICING_RULES.END_DATE") }}</dt>
          <dd>
            {{ pricingRule.end_date }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.EXCERPT") }}</dt>
          <dd>
            {{
              pricingRule.description ?? $t("PRICING_RULES.EMPTY_DESCRIPTION")
            }}
          </dd>
        </dl>
        <dl class="row" v-if=" pricingRule.minimum_person !=-null ">
          <dt>{{ $t("PRICING_RULES.MINIMUM_PERSON") }}</dt>
          <dd>
            {{
              pricingRule.minimum_person
            }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.maximum_person !==null">
          <dt>{{ $t("PRICING_RULES.MAXIMUM_PERSON") }}</dt>
          <dd>
            {{
              pricingRule.maximum_person
            }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.minimum_stay !==null">
          <dt>{{ $t("PRICING_RULES.MINIMUM_NIGHT") }}</dt>
          <dd>
            {{
              pricingRule.minimum_stay
            }}
          </dd>
        </dl>
        <dl class="row" v-if="pricingRule.maximum_stay !==null">
          <dt>{{ $t("PRICING_RULES.MAXIMUM_NIGHT") }}</dt>
          <dd>
            {{
              pricingRule.maximum_stay
            }}
          </dd>
        </dl>
        <dl class="row">
          <dt>{{ $t("COMMON.CREATED_AT") }}</dt>
          <dd>
            {{ pricingRule.created_at | moment("LLLL") }}
          </dd>
        </dl>

        <dl class="row">
          <dt>{{ $t("COMMON.UPDATED_AT") }}</dt>
          <dd>
            {{ pricingRule.updated_at | moment("LLLL") }}
          </dd>
        </dl>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "pricing-rule-view-global",

  props: ["pricingRule"],

  components: {},

  data() {
    return {};
  },

  computed: {},

  created() {},

  methods: {
    copyAllInfo() {
      const pricingRuleData = cloneDeep(this.pricingRule);
      const pricingRuleString = JSON.stringify(pricingRuleData, null, 2);

      navigator.clipboard
        .writeText(pricingRuleString)
        .then(() => {
          this.$notify({
            type: "success",
            message: this.$t("PRICING_RULES.PRICING_RULE_COPIED"),
          });
        })
        .catch((err) => {
          this.$notify({
            type: "danger",
            message: this.$t("PRICING_RULES.COPY_FAILURE"),
          });
        });
    },
    contactUpdated() {
      this.$emit("pricingRuleUpdated", true);
    },
  },

  mounted() {},

  watch: {
    contact(contact) {},
  },
};
</script>
