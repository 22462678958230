<template>
  <div class="step-content-body">
    <div class="step-content-body-title">
      <div class="step-content-body-title-left">
        <p>{{ $t("PRICING_RULES.WEB") }}</p>
      </div>
      <div class="step-content-body-title-right"></div>
    </div>
    <div class="form-group-wrapper">
      <div class="form-wrapper width_1-2 has-button">
        <base-input :label="`${$t('PRICING_RULES.PROMO_CODE')}`">
          <el-select
            v-model="codeValue"
            :placeholder="`${$t('PRICING_RULES.PROMO_CODE')}`"
          >
            <el-option
              v-for="code in codes"
              :key="code.value"
              :label="code.label"
              :value="code.value"
            >
            </el-option>
          </el-select>
          <base-button :label="`${$t('PRICING_RULES.ADD_ID')}`">
            <i class="fal fa-plus"></i>
            {{ $t("PRICING_RULES.CREATE") }}
          </base-button>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <label>{{ $t("PRICING_RULES.PUBLISH_ONLINE_RESERVATION") }}</label>
        <base-checkbox v-model="publishOnlineReservation" />
      </div>
      <div class="form-wrapper width_1-2 has-button">
        <base-input :label="`${$t('PRICING_RULES.BADGE')}`">
          <el-select
            v-model="badgeValue"
            :placeholder="`${$t('PRICING_RULES.BADGE')}`"
          >
            <el-option
              v-for="badge in badges"
              :key="badge.value"
              :label="badge.label"
              :value="badge.value"
            >
            </el-option>
          </el-select>
          <base-button :label="`${$t('COMMON.CREATE')}`">
            <i class="fal fa-plus"></i>
            {{ $t("PRICING_RULES.CREATE") }}
          </base-button>
        </base-input>
      </div>
      <div class="form-wrapper width_1-2">
        <base-input :label="`${$t('PRICING_RULES.DISCOUNT_RATES_DISPLAY')}`">
          <el-input-number
            v-model="discountRate"
            controls-position="right"
            :min="0"
            :max="200"
          ></el-input-number>
        </base-input>
      </div>
    </div>
  </div>
</template>
<script>
import { Option, Radio, Select, Checkbox, InputNumber } from "element-ui";
export default {
  name: "web-step",

  components: {
    [Select.name]: Select,
    [Option.name]: Option,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    [InputNumber.name]: InputNumber,
  },

  props: [],

  mixins: [],

  data() {
    return {
      codes: [
        {
          value: 1,
          label: "Id 1",
        },
        {
          value: 2,
          label: "Id 2",
        },
      ],
      codeValue: "",
      badges: [
        {
          value: 1,
          label: "CAA",
        },
        {
          value: 2,
          label: "CAB",
        },
      ],
      badgeValue: "",
      publishOnlineReservation: true,
      discountRate: 0,
    };
  },

  computed: {},

  methods: {},

  watch: {},
};
</script>
