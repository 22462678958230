<template>
  <div class="step-content-body">
    <div class="step-content-body-title">
      <div class="step-content-body-title-left">
        <p>{{ $t("COMMON.EXCERPT") }}</p>
      </div>
      <div class="step-content-body-title-right"></div>
    </div>
    <div class="form-group-wrapper">
      <div class="form-wrapper description">
        <base-input
          :label="`${
            $t('COMMON.EXCERPT') + ' ' + $t('PRICING_RULES.MAX_CARACTERS')
          }`"
        >
          <el-input type="textarea" v-model="textarea"> </el-input>
        </base-input>
      </div>
    </div>
  </div>
</template>
<script>
import { Input } from "element-ui";
export default {
  name: "description-step",

  components: {
    [Input.name]: Input,
  },

  props: {
    description: {
      type: String,
      default: null,
      description: "Disable the input",
    },
  },

  mixins: [],

  data() {
    const textarea = this.description;
    return {
      textarea,
    };
  },

  computed: {},

  methods: {},

  watch: {
    textarea(value) {
      this.$emit("update:description", value);
    },
  },
};
</script>
