<template>
  <div></div>
</template>
<script>
import { cloneDeep } from "lodash";
import {
  Checkbox,
  CheckboxButton,
  CheckboxGroup,
  Option,
  DatePicker,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableColumn,
} from "element-ui";
import formMixin from "@/mixins/form-mixin";
import ValidationError from "@/components/ValidationError.vue";
import SpotTypesSelector from "@/components/SpotTypesSelector";
import PricingRuleSelector from "@/components/PricingRuleSelector";
import OrganizationSelector from "@/components/OrganizationSelector.vue";
import BaseInput from "@/components/Inputs/BaseInput.vue";

import {
  signVariationOption,
  calculationVariationOption,
  pricingOption,
} from "@/constants/pricingRules";
import "flatpickr/dist/flatpickr.css";

export default {
  name: "prices-step",

  components: {
    BaseInput,
    ValidationError,
    OrganizationSelector,
    PricingRuleSelector,
    SpotTypesSelector,
    [Select.name]: Select,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [DatePicker.name]: DatePicker,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
    [CheckboxButton.name]: CheckboxButton,
  },

  mixins: [formMixin],

  props: ["pricingRuleData", "formErrors", "loading", "hideSubmit"],

  data() {
    let selectedDateRange = [];

    let pricingRuleData = { ...this.pricingRuleData };
    pricingRuleData = this.$fillUserOrganizationData(pricingRuleData);
    if (this.pricingRuleData.start_date) {
      selectedDateRange = [
        this.pricingRuleData.start_date,
        this.pricingRuleData.end_date,
      ];
    }
    return {
      signOption: signVariationOption,
      calculationOption: calculationVariationOption,
      pricingTypeOption: pricingOption,
      selectedDateRange: selectedDateRange,
      pricingRule: pricingRuleData,
      pricingRules: [],
      total: 0,
      showPricingRuleCheckModal: false,
      processing: false,
    };
  },

  computed: {},

  methods: {
    async getList() {
      if (!this.pricingRule?.name) {
        return;
      }
      try {
        this.processing = true;
        let params = {
          ...(this.sort ? { sort: this.sort } : {}),
          filter: {
            ...{
              search: this.pricingRule?.name,
            },
          },
          page: {
            number: 1,
            size: 999,
          },
          include: "",
        };

        await this.$store.dispatch("pricingRules/list", params);
        this.pricingRules = this.$store.getters["pricingRules/list"];
        this.total = this.$store.getters["pricingRules/listTotal"];
        this.processing = false;
      } catch (error) {
        this.processing = false;
        this.$notify({
          type: "danger",
          message: this.$t("ERRORS.SOMETHING_WENT_WRONG"),
        });
      }
    },

    onDateSelected(range) {
      this.pricingRule.start_date = range[0];
      this.pricingRule.end_date = range[1];
      this.onFormChanged();
    },
  },

  watch: {
    formErrors(errors) {
      if (errors) {
        this.setApiValidation(errors);
      }
    },
    pricingRuleData(pricingRuleData) {
      if (pricingRuleData) {
        this.pricingRule = {
          ...this.pricingRule,
          ...cloneDeep(pricingRuleData),
        };
      }
    },
  },
};
</script>
